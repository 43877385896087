<template>
  <div class="mx-1">
    <b-row v-if="formLoaded">
      <b-col align="right">
        <b-button
          class="btn-create mt-2"
          type="button"
          variant="primary"
          size="sm"
          @click="openUploadDocument"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>
    <table-document
      :fields="fields"
      @destroy="deleteDocument"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['destroy']"
      noInnerWidth
      @download="downloadDocument"
    >
    </table-document>
    <add-document-modal
      v-if="formLoaded"
      :beFormCreate="beForm[repository]"
      :beRulesCreate="beRules[repository]"
      @upload="uploadDocument"
    >
    </add-document-modal>
  </div>
</template>

<script>
import TableDocument from "@/components/tables/Documents";
import AddDocumentModal from "@/components/modals/addDocument";
import FormMixin from "@/mixins/FormMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";

export default {
  mixins: [FormMixin, DetailTabAutofetchTableMixin, DocumentsMixin],
  name: "Documents",
  data() {
    return {
      filter: this.initFilter(),
      formLoaded: false,
      filterName: "filterRegistryDocumentsDetail",
      repository: "document",
      resource: "documents",
      tableRef: "registryDocumentsDetailTableRef",
      relation: "registry",
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "document"),
          sortable: false,
          sortKey: "title",
        },
        {
          key: "note",
          label: this.getDictionary("note", "document"),
          sortable: false,
          sortKey: "note",
        },
        {
          key: "extension",
          label: this.getDictionary("source_type", "document"),
          sortable: false,
          sortKey: "extension",
        },
        {
          key: "document_type.text",
          label: this.getDictionary("document_type", "document"),
          sortable: false,
          sortKey: "document_type.text",
        },
        {
          key: "download",
          label: this.getDictionary("Scarica Documento"),
          tdClass: "text-center",
          thStyle: {
            width: "125px",
          },
        },
      ],
    };
  },
  components: {
    TableDocument,
    AddDocumentModal,
  },
  methods: {
    initFilter() {
      let init = {
        byRegistry: { id: this.resourceId },
      };
      return init;
    },
    openUploadDocument() {
      this.$bvModal.show("addDocumentModal");
    },
  },
  props: {
    resourceId: Number,
  },
  beforeMount() {
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.formLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
