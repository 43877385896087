import { mapActions } from "vuex";
import { getDictionary } from "@/utils/dictionary";
/**
 * NOTA 1: i componenti (tab) che usano questo mixin implementano gli hooks activated e deactivated
 * la fetch (onSearch) va eseguita nel hook activated, e il componente (tab) NON deve chiamare la fetch nel hook mounted
 * gli atri tab, che non usano questo mixin, invece chiamano la fetch nel hook mounted.
 * NOTA 2: in entrambi in casi, all'istanza <component :is="selected"> va aggiunta questa condizione:
 *  v-if="selected === tab.name"
 * per evitare che cambiando tab venga chiamata la fetch del componente selezionato ad anche di quello selezionato in precedenza
 * NOTA 3: al momento in cui scrivo, non mi è chiaro perché avvenga questa doppia chiamata.
 */
export default {
  data() {
    return {
      isTabLoading: false,
      filter: null,
      filterName: null,
      tableRef: null,
    };
  },
  methods: {
    getDictionary,
    onSearch(name) {
      const tableRef = this.$refs[this.tableRef];
      this.isTabLoading = true;
      let criteria = this.filter;

      this.saveFilterByName({ name, criteria });
      if (tableRef !== undefined) {
        tableRef
          .fetch(this.args)
          .then(() => {
            this.removeFilterByName(name);
            this.removePaginationByName(name);
            this.isTabLoading = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          });
      }
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {
      removePaginationByName: "removeByName",
    }),
  },
  mounted() {
    console.log(`tab ${this.$options.name} mounted`);
    // fetch data
    // this.onSearch(this.filterName);
  },
  // keep-alive hooks
  activated() {
    console.log(`${this.$options.name} tab activated`);
    this.onSearch(this.filterName);
  },
  deactivated() {
    console.log(`${this.$options.name} tab deactivated`);
  },
};
